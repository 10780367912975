<template>
  <div class="mb-5">
    <header class="svg-logo d-flex align-center justify-center">
      <v-img class="mr-5" max-width="50px" src="../../../public/assets/img/svg/four-calculator.svg"></v-img>
      <h1 class="text-h5">Four Calculator</h1>
    </header>
    <p>Privacy policy: The Android app doesn't collect any data.</p>

    Credit:
    <Url :dark-mode="$vuetify.theme.isDark" href="https://www.youtube.com/watch?v=csInNn6pfT4&t=12m46s">Vsauce </Url>

    <v-form ref="form" class="mt-4" autocomplete="off">
      <input autocomplete="off" type="hidden" />
      <v-text-field
        ref="input"
        v-model.trim="input"
        autofocus
        class="mt-5"
        label="Type anything"
        type="text"
      ></v-text-field>
    </v-form>

    <transition name="slide">
      <div v-if="isConvertTo4" class="output">
        How does this {{ input.length }}-{{ getIsNumber(input) ? "digit number" : "letter phrase" }} turn into 4?<br />
        <div v-for="(input, i) of calculationSteps" :key="i">
          <span class="iteration">#{{ i + 1 }})</span>
          {{ commaSeparated(input) }}
          <span v-show="getIsNumber(input)">
            <v-icon>{{ mdiArrowRight }}</v-icon>
            {{ getNumberOrPhrase(input) }}
          </span>
          -
          {{ getNumberOrPhrase(input).length }}
          letters
        </div>

        <v-icon color="green">{{ mdiCheckBold }}</v-icon>
        Success!
      </div>
    </transition>
  </div>
</template>

<script>
import { convertTo4 } from "@/views/products/calculators";
import { mdiArrowRight, mdiCheckBold } from "@mdi/js";
import Url from "@/components/Url";
import { mapGetters } from "vuex";
import { ToWords } from "to-words";

export default {
  name: "FourCalculator",
  components: { Url },
  data() {
    return {
      input: "",
      calculationSteps: [],
      mdiCheckBold,
      mdiArrowRight
    };
  },
  watch: {
    async input(input) {
      this.calculationSteps = await convertTo4(input);
    }
  },
  computed: {
    ...mapGetters(["commaSeparated"]),
    isConvertTo4() {
      return this.input.length > 0;
    }
  },
  methods: {
    getIsNumber(input) {
      try {
        BigInt(input || "e");
        return true;
      } catch {
        return false;
      }
    },
    getNumberOrPhrase(input) {
      try {
        return new ToWords({ localeCode: "en-US" }).convert(input);
      } catch {
        return input;
      }
    }
  }
};
</script>

<style scoped>
.output {
  white-space: nowrap;
  overflow-y: hidden;
}

/*noinspection CssUnusedSymbol*/
.slide-leave,
.slide-enter-to {
  max-height: 100vh;
}

.iteration {
  color: blue;
}

@media (prefers-color-scheme: dark) {
  .iteration {
    color: cyan;
  }
}
</style>
