import Worker from "worker-loader!./workers";
import { wrap } from "comlink";

const worker = wrap(new Worker());

export const convertTo9 = worker.convertTo9;
export const convertTo4 = worker.convertTo4;
export const calculateFibonacci = worker.calculateFibonacci;
export const convertToPalindrome = worker.convertToPalindrome;
export const convertTime = worker.convertTime;
